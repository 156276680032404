<template>
  <div>
    <page-title :heading="$t('accounting.lang_nav_lieferschein')" :subheading="$t('accounting.lang_nav_lieferschein')" :icon=icon show-add-button :permission-add="true"></page-title>

    <div class="app-main__inner">
      <DeliveryNotesComponent/>
    </div>
  </div>
</template>

<script>
import PageTitle from "@/Layout/Components/PageTitle";
import DeliveryNotesComponent from "@/components/billing/deliverynote/DeliveryNotesComponent";


export default {
  name: "DeliveryNotes",
  components: {DeliveryNotesComponent, PageTitle},
  data: () => ({
    icon: 'pe-7s-note2 icon-gradient bg-tempting-azure',
  })
}
</script>

<style scoped>

</style>
